/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useEffect, useState } from 'react'
import type { FC } from 'react'
import { navigate } from 'gatsby'
import { Center, Container, Spinner, SuspenseSSR } from '@vtex/store-ui'
import { useWishlist } from 'src/contexts/WishlistContext/useWishlist'
import Layout from 'src/components/Layout'
import { authService, searchProductsService } from 'src/services/wishlist'
import type { ISession } from 'src/contexts/WishlistContext'
import { localizedPath, useIntl } from '@vtex/gatsby-plugin-i18n'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const loader = () => import('src/views/wishlist')
const WishlistPage = lazy(loader)

const Loading: FC = () => (
  <Center height="750px">
    <Spinner />
  </Center>
)

const Page: FC = () => {
  const { items } = useWishlist()
  const [session, setSession] = useState<ISession | null>(null)
  const [productItems, setProductItems] = useState<any | null>(null)
  const [loadingSession, setLoadingSession] = useState(true)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const { locale, defaultLocale } = useIntl()

  const getAuth = async () => {
    const response = await authService()

    setSession(response)
    setLoadingSession(false)
  }

  const loadProducts = async () => {
    let itemsQuery = ''

    if (items.length > 0) {
      setLoadingProducts(true)
      items.map((item: string) => (itemsQuery += `fq=productId:${item}&`))
      const response = await searchProductsService(itemsQuery)

      setLoadingProducts(false)

      if (response.length > 0) {
        setProductItems(response)
      } else {
        setProductItems([])
      }
    }

    return null
  }

  useEffect(() => {
    getAuth()
  }, [])

  useEffect(() => {
    if (!loadingSession) {
      if (session) {
        loadProducts()
      } else {
        const path = localizedPath(defaultLocale, locale, '/')

        navigate(path)
      }
    }
  }, [session, loadingSession, items])

  return (
    <>
      <GatsbySeo
        title="Minha conta"
        description="Minha conta"
        titleTemplate="%s | Avon"
        canonical="https://www.avon.com.br"
      />
      <Layout>
        <Container>
          <SuspenseSSR fallback={<Loading />}>
            <WishlistPage loading={loadingProducts} products={productItems} />
          </SuspenseSSR>
        </Container>
      </Layout>
    </>
  )
}

export default Page
